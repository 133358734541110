import './footer.css';
import { AiOutlineGithub, AiFillLinkedin, AiOutlineMail, AiOutlineInstagram } from 'react-icons/ai';
import { BiCopyright } from 'react-icons/bi';


const Footer = () => {
    // Ottieni l'anno corrente
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer-container">
            
            <div className="footer-socials">
                <a href="https://github.com/JohnnyBeGood19"><AiOutlineGithub /></a>
                <a href="https://www.linkedin.com/in/johnmarcotolentino/"><AiFillLinkedin /></a>
                <a href="https://www.instagram.com/johnmarco.things/"><AiOutlineInstagram /></a>
                <a href="mailto:john.marco.tolentino@gmail.com"><AiOutlineMail /></a>
           
                
            </div>
            <div className="footer-info">
                <BiCopyright /> { currentYear } John Marco Tolentino
            </div>
        </div>
    );
}

export default Footer;